.top-signin {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* SignIn css labels */
.sign-in {
    text-align: center;
}

.email-text-field fieldset {
    border-radius: 100px;
}

.password-text-field fieldset {
    border-radius: 100px;
}

.sign-in-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-button {
    width: 150px;
    height: 50px;
}

.forgot-password {
    margin-bottom: 3em;
}

.new-here {
    align-items: center;
}

/* NewUserStudent css labels */
.sign-up {
    text-align: center;
}

.confirm-password-text-field fieldset {
    border-radius: 100px;
}

.continue-to-profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.continue-to-profile-btn {
    width: 210px;
    height: 50px;
}

.forgot-password {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* UserProfile labels */
.top-update-profile {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.update-email-text-field fieldset {
    border-radius: 100px;
}

.update-password-text-field fieldset {
    border-radius: 100px;
}

.cancel {
    margin-top: 5%;
}

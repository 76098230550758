.top-welcomepage {
    background-position: center;
    text-align: center;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 75px;
}

h1 {
    font-size: large;
}

.welcome-image {
    display: block;
   
    margin-left: auto;
    margin-right: auto;
}

.message {
    color: white;
    text-align: center;
    padding-bottom: 5%;
}

.welcome-info {
    text-align: center;
    font-size: large;
    padding-top: 20px;
}

.checkmark {
    display: block;
    height: 50px;
    width: 60px;
    padding-top: 2.5%;
    padding-left: 40%;
   
}

.lightbulb {
    display: block;
    height: 60px;
    width: 70px;
    padding: 0%;
    padding-left: 40%;
}

.connection {
    display: block;
    height: 60px;
    width: 70px;
    padding: 0%;
    padding-left: 40%;
}

.title {
    padding: 0%;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 0%;
}

.info {
    padding: 0%;
    color: white;
    text-align: center;
    font-style: italic;
    font-weight: normal;
    margin-top: 0%;
}

.getstarted {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    /*stack order*/
    z-index: -1;
}

/* #check {
    text-align: 'left';
    animation-duration: 2s;
    font-size: 55px;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        top: 0;
        left: 0;
    }
    to {
        top: 100px;
        left: 100px;
    }
} */

@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@1,300&family=Oswald:wght@200&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.makeStyles-img-5 {
    padding-top: 0%;
}
image:hover {
    background-color: black;
}
.content {
    padding: 0;
    flex: '1 0 auto' 1;
}
#title-project {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

#Title {
    text-align: 'left';
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    font-size: 55px;
    -webkit-animation-name: slidein;
            animation-name: slidein;
}
@-webkit-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

#desc {
    font-size: 13px;
    font-style: italic;
}

#menu {
    justify-content: end;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: white;
    padding: 16px;
}

.top-signin {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* SignIn css labels */
.sign-in {
    text-align: center;
}

.email-text-field fieldset {
    border-radius: 100px;
}

.password-text-field fieldset {
    border-radius: 100px;
}

.sign-in-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-button {
    width: 150px;
    height: 50px;
}

.forgot-password {
    margin-bottom: 3em;
}

.new-here {
    align-items: center;
}

/* NewUserStudent css labels */
.sign-up {
    text-align: center;
}

.confirm-password-text-field fieldset {
    border-radius: 100px;
}

.continue-to-profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.continue-to-profile-btn {
    width: 210px;
    height: 50px;
}

.forgot-password {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* UserProfile labels */
.top-update-profile {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.update-email-text-field fieldset {
    border-radius: 100px;
}

.update-password-text-field fieldset {
    border-radius: 100px;
}

.cancel {
    margin-top: 5%;
}

/* Main Tags for all profile pages */
.new-profile {
    display: flex;
    flex-direction: row;
}

.middle-screen {
    width: 5%;
}

.right-screen {
    text-align: center;
    width: 60%;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.right-screen-proj {
    text-align: center;
    width: 50%;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.right-most-screen {
    width: 5%;
}

.profile-image {
    height: 54px;
    width: 50px;
    padding-top: 10px;
    margin-bottom: 0%;
}

h1 {
    text-align: center;
    margin-top: 0%;
    margin-bottom: 0%;
    font-size: small;
}

.left-text-info {
    font-size: xx-large;
    color: white;
    text-align: center;
    padding-top: 50%;
}

.profile {
    font-size: medium;
    font-style: normal;
    color: black;
    text-align: center;
    margin-top: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0%;
}

.profile-pic-position {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
}

.profile-pic {
    height: 200px;
    width: 200px;
    padding-top: 0%;
}

/* StudentProfile Tags */
.left-screen-student {
    background-color: #107fb7;
    width: 40%;
}

.first-name {
    margin-bottom: 1%;
}

.first-name fieldset {
    border-radius: 100px;
}

.middle-name {
    margin-bottom: 1%;
}

.middle-name fieldset {
    border-radius: 100px;
}

.last-name {
    margin-bottom: 1%;
}

.last-name fieldset {
    border-radius: 100px;
}

.preferred-name {
    margin-bottom: 1%;
}

.preferred-name fieldset {
    border-radius: 100px;
}

.pronouns {
    margin-bottom: 4%;
}

.pronouns fieldset {
    border-radius: 100px;
}

.year-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    font-size: medium;
}

.major-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    font-size: medium;
}

.minor-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    font-size: medium;
}

.skills {
    font-size: medium;
    margin-bottom: 1%;
}

.skills fieldset {
    border-radius: 100px;
}

.self-intro {
    font-size: small;
    font-weight: bold;
    font-style: normal;
    border-color: #107fb7;
}

.portfolio {
    margin-bottom: 4%;
}

.portfolio fieldset {
    border-radius: 100px;
}

/* FacultyStaffProfile Tags */
.left-screen-fs {
    background-color: #ac0c30;
    width: 40%;
}

.fs-title {
    margin-bottom: 1%;
}

.fs-title fieldset {
    border-radius: 100px;
}

.fs-first-name {
    margin-bottom: 1%;
}

.fs-first-name fieldset {
    border-radius: 100px;
}

.fs-middle-name {
    margin-bottom: 1%;
}

.fs-middle-name fieldset {
    border-radius: 100px;
}

.fs-last-name {
    margin-bottom: 1%;
}

.fs-last-name fieldset {
    border-radius: 100px;
}

.fs-preferred-name {
    margin-bottom: 1%;
}

.fs-preferred-name fieldset {
    border-radius: 100px;
}

.fs-pronouns {
    margin-bottom: 4%;
}

.fs-pronouns fieldset {
    border-radius: 100px;
}

.department-fsprofile {
    margin-bottom: 15px;
}

.department-options {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    font-size: medium;
}

.lab-description {
    font-size: small;
    font-weight: bold;
    font-style: normal;
    border-color: #ac0c30;
    margin-bottom: 1%;
}

.fs-portfolio {
    margin-bottom: 4%;
}

.fs-portfolio fieldset {
    border-radius: 100px;
}

.resume {
    font-size: medium;
    font-style: normal;
}

.button1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CreateProject Tags */
.left-screen-project {
    background-color: gray;
    width: 40%;
}

.post-proj-btn1 {
    height: 60px;
    width: 150px;
}

.project-name {
    font-size: medium;
    margin-bottom: 1%;
}

.project-name fieldset {
    border-radius: 100px;
}

.project-desc {
    margin-bottom: 4%;
}

.members-needed {
    font-size: x-large;
    margin-right: 10px;
}

.members-dropdown,
.project-status {
    height: 50px;
    width: 100%;
    font-size: medium;
    text-align: center;
    font-size: x-large;
    color: gray;
    margin-bottom: 5%;
}

.project-majors {
    text-align: left;
    font-size: large;
}

.preferred-majors-options {
    font-size: x-large;
    margin-bottom: 4%;
}

.preferred-years {
    font-size: large;
    text-align: left;
}

.preferred-years-options {
    font-size: x-large;
    margin-bottom: 1%;
}

.preferred-soft-skill {
    font-size: medium;
    margin-bottom: 4%;
}

.preferred-soft-skill fieldset {
    border-radius: 100px;
}

.project-timeline {
    font-size: x-large;
    margin-right: 10px;
}

.timeline-dropdown {
    height: 50px;
    width: 100%;
    font-size: medium;
    text-align: center;
    font-size: x-large;
    color: gray;
    margin-bottom: 5%;
}

.incentive-options {
    height: 50px;
    width: 100%;
    font-size: medium;
    margin-bottom: 6%;
}

input {
    height: 50px;
    width: 260px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 100px;
    text-align: left;
}

textarea {
    margin-top: 10px;
    height: 150px;
    width: 270px;
}

/* Opening animation of profile pages */
#left-text {
    text-align: 'left';
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    font-size: 55px;
    -webkit-animation-name: slidein;
            animation-name: slidein;
}

@-webkit-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.top-welcomepage {
    background-position: center;
    text-align: center;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 75px;
}

h1 {
    font-size: large;
}

.welcome-image {
    display: block;
   
    margin-left: auto;
    margin-right: auto;
}

.message {
    color: white;
    text-align: center;
    padding-bottom: 5%;
}

.welcome-info {
    text-align: center;
    font-size: large;
    padding-top: 20px;
}

.checkmark {
    display: block;
    height: 50px;
    width: 60px;
    padding-top: 2.5%;
    padding-left: 40%;
   
}

.lightbulb {
    display: block;
    height: 60px;
    width: 70px;
    padding: 0%;
    padding-left: 40%;
}

.connection {
    display: block;
    height: 60px;
    width: 70px;
    padding: 0%;
    padding-left: 40%;
}

.title {
    padding: 0%;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 0%;
}

.info {
    padding: 0%;
    color: white;
    text-align: center;
    font-style: italic;
    font-weight: normal;
    margin-top: 0%;
}

.getstarted {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    /*stack order*/
    z-index: -1;
}

/* #check {
    text-align: 'left';
    animation-duration: 2s;
    font-size: 55px;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        top: 0;
        left: 0;
    }
    to {
        top: 100px;
        left: 100px;
    }
} */

.about-container {
    display: flex;
}

.column-right {
    width: 50%;
    display: table-cell;
    padding: 10px;
    margin-top: 8rem;
}

.column-left {
    width: 50%;
    display: table-cell;
}
.column-right.details {
    width: 60%;
    display: table-cell;
    margin-top: 5px;
}

.column-left.details {
    width: 40%;
    display: table-cell;
}

.action-items {
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    align-items: center;
}

.sidebar {
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: rgb(249, 249, 249);
    box-shadow: 30px;
    /* z-index: -1; */
}

.side-text-title {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 0px 0px 10px;
    list-style: none;
    height: 60px;
    -webkit-animation-name: slidein;
            animation-name: slidein;
}

.side-text-title a {
    text-decoration: none;
    color: rgb(74, 149, 240);
    font-size: 20px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.side-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 45px 0px 8px 16px;
    list-style: none;
    height: 60px;
    -webkit-animation-name: slidein;
            animation-name: slidein;
}

.side-text a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: 'Oswald', sans-serif; */
}

.side-text a:hover {
    /* background-color: brown; */
    color: rgb(77, 128, 191);
    border-radius: 0%;
}

#dash-title {
    -webkit-animation-name: slidein;
            animation-name: slidein;
}

@-webkit-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}


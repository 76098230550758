.makeStyles-img-5 {
    padding-top: 0%;
}
image:hover {
    background-color: black;
}
.content {
    padding: 0;
    flex: '1 0 auto';
}
#title-project {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

#Title {
    text-align: 'left';
    animation-duration: 2s;
    font-size: 55px;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

#desc {
    font-size: 13px;
    font-style: italic;
}

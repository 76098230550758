@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@1,300&family=Oswald:wght@200&display=swap');

.sidebar {
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: rgb(249, 249, 249);
    box-shadow: 30px;
    /* z-index: -1; */
}

.side-text-title {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 0px 0px 10px;
    list-style: none;
    height: 60px;
    animation-name: slidein;
}

.side-text-title a {
    text-decoration: none;
    color: rgb(74, 149, 240);
    font-size: 20px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.side-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 45px 0px 8px 16px;
    list-style: none;
    height: 60px;
    animation-name: slidein;
}

.side-text a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: 'Oswald', sans-serif; */
}

.side-text a:hover {
    /* background-color: brown; */
    color: rgb(77, 128, 191);
    border-radius: 0%;
}

#dash-title {
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

/* Main Tags for all profile pages */
.new-profile {
    display: flex;
    flex-direction: row;
}

.middle-screen {
    width: 5%;
}

.right-screen {
    text-align: center;
    width: 60%;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.right-screen-proj {
    text-align: center;
    width: 50%;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.right-most-screen {
    width: 5%;
}

.profile-image {
    height: 54px;
    width: 50px;
    padding-top: 10px;
    margin-bottom: 0%;
}

h1 {
    text-align: center;
    margin-top: 0%;
    margin-bottom: 0%;
    font-size: small;
}

.left-text-info {
    font-size: xx-large;
    color: white;
    text-align: center;
    padding-top: 50%;
}

.profile {
    font-size: medium;
    font-style: normal;
    color: black;
    text-align: center;
    margin-top: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0%;
}

.profile-pic-position {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
}

.profile-pic {
    height: 200px;
    width: 200px;
    padding-top: 0%;
}

/* StudentProfile Tags */
.left-screen-student {
    background-color: #107fb7;
    width: 40%;
}

.first-name {
    margin-bottom: 1%;
}

.first-name fieldset {
    border-radius: 100px;
}

.middle-name {
    margin-bottom: 1%;
}

.middle-name fieldset {
    border-radius: 100px;
}

.last-name {
    margin-bottom: 1%;
}

.last-name fieldset {
    border-radius: 100px;
}

.preferred-name {
    margin-bottom: 1%;
}

.preferred-name fieldset {
    border-radius: 100px;
}

.pronouns {
    margin-bottom: 4%;
}

.pronouns fieldset {
    border-radius: 100px;
}

.year-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    font-size: medium;
}

.major-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    font-size: medium;
}

.minor-dropdown {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    font-size: medium;
}

.skills {
    font-size: medium;
    margin-bottom: 1%;
}

.skills fieldset {
    border-radius: 100px;
}

.self-intro {
    font-size: small;
    font-weight: bold;
    font-style: normal;
    border-color: #107fb7;
}

.portfolio {
    margin-bottom: 4%;
}

.portfolio fieldset {
    border-radius: 100px;
}

/* FacultyStaffProfile Tags */
.left-screen-fs {
    background-color: #ac0c30;
    width: 40%;
}

.fs-title {
    margin-bottom: 1%;
}

.fs-title fieldset {
    border-radius: 100px;
}

.fs-first-name {
    margin-bottom: 1%;
}

.fs-first-name fieldset {
    border-radius: 100px;
}

.fs-middle-name {
    margin-bottom: 1%;
}

.fs-middle-name fieldset {
    border-radius: 100px;
}

.fs-last-name {
    margin-bottom: 1%;
}

.fs-last-name fieldset {
    border-radius: 100px;
}

.fs-preferred-name {
    margin-bottom: 1%;
}

.fs-preferred-name fieldset {
    border-radius: 100px;
}

.fs-pronouns {
    margin-bottom: 4%;
}

.fs-pronouns fieldset {
    border-radius: 100px;
}

.department-fsprofile {
    margin-bottom: 15px;
}

.department-options {
    height: 50px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    font-size: medium;
}

.lab-description {
    font-size: small;
    font-weight: bold;
    font-style: normal;
    border-color: #ac0c30;
    margin-bottom: 1%;
}

.fs-portfolio {
    margin-bottom: 4%;
}

.fs-portfolio fieldset {
    border-radius: 100px;
}

.resume {
    font-size: medium;
    font-style: normal;
}

.button1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CreateProject Tags */
.left-screen-project {
    background-color: gray;
    width: 40%;
}

.post-proj-btn1 {
    height: 60px;
    width: 150px;
}

.project-name {
    font-size: medium;
    margin-bottom: 1%;
}

.project-name fieldset {
    border-radius: 100px;
}

.project-desc {
    margin-bottom: 4%;
}

.members-needed {
    font-size: x-large;
    margin-right: 10px;
}

.members-dropdown,
.project-status {
    height: 50px;
    width: 100%;
    font-size: medium;
    text-align: center;
    font-size: x-large;
    color: gray;
    margin-bottom: 5%;
}

.project-majors {
    text-align: left;
    font-size: large;
}

.preferred-majors-options {
    font-size: x-large;
    margin-bottom: 4%;
}

.preferred-years {
    font-size: large;
    text-align: left;
}

.preferred-years-options {
    font-size: x-large;
    margin-bottom: 1%;
}

.preferred-soft-skill {
    font-size: medium;
    margin-bottom: 4%;
}

.preferred-soft-skill fieldset {
    border-radius: 100px;
}

.project-timeline {
    font-size: x-large;
    margin-right: 10px;
}

.timeline-dropdown {
    height: 50px;
    width: 100%;
    font-size: medium;
    text-align: center;
    font-size: x-large;
    color: gray;
    margin-bottom: 5%;
}

.incentive-options {
    height: 50px;
    width: 100%;
    font-size: medium;
    margin-bottom: 6%;
}

input {
    height: 50px;
    width: 260px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 100px;
    text-align: left;
}

textarea {
    margin-top: 10px;
    height: 150px;
    width: 270px;
}

/* Opening animation of profile pages */
#left-text {
    text-align: 'left';
    animation-duration: 1s;
    font-size: 55px;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.about-container {
    display: flex;
}

.column-right {
    width: 50%;
    display: table-cell;
    padding: 10px;
    margin-top: 8rem;
}

.column-left {
    width: 50%;
    display: table-cell;
}
.column-right.details {
    width: 60%;
    display: table-cell;
    margin-top: 5px;
}

.column-left.details {
    width: 40%;
    display: table-cell;
}

.action-items {
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    align-items: center;
}
